import { useState, useRef } from "react"
import { useTranslation } from 'react-i18next';

import userStore from '../store/user';
import { useHistory } from "react-router-dom";

import { Auth, API, Storage, graphqlOperation } from 'aws-amplify'
import aws_exports from "../aws-exports";
import { createMessage, createOrder, updateChat } from '../graphql/mutations';
import { listCompanys, listUsers } from '../graphql/queries';

import { Button, Input, Layout } from "element-react"
import { FiSend, FiFile, FiX, FiBook } from "react-icons/fi";
import { ImAttachment } from "react-icons/im";
import {FILES_URL} from '../utils/Constants';
import { blobToBase64 } from '../utils/FormatUtils';

export default function ChatMessageInputs({ chat, onMessageSent }) {
    const history = useHistory();
    const { user } = userStore();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const attachmentInput = useRef(null)  
    const [[inputText, setInputText], [attachment, setAttachment], [sendingMessage, setSendingMessage]] = [useState(""), useState(null), useState(false)]
    const [base64Attachment, setBase64Attachment] = useState('');
    const toCognitoId = chat.chat_type === "S" ? chat.owner : chat.company_user;
    
    let maxOrderNumberQuery = /* GraphQL */ `
        query listOrders(
            $filter: ModelOrderFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                orderNumber
            }
            nextToken
            }
        }
    `;

    async function handleTextMessageSubmit() {
        if(inputText === "") return;
        try{
            setSendingMessage(true)
            const createMessageInput= {
                chat_id: chat.id,
                messageChatId: chat.id,
                from: user.cognitoId,
                message: inputText,
                message_type: "message",
                to: toCognitoId
            }
            
            const updateChatInput= {
                id: chat.id,
                last_message: inputText,
            }
            updateChatInput[chat.chat_type === "S" ? "owner_last_message_read" : "company_user_last_message_read"]= 1
            updateChatInput[chat.chat_type === "S" ? "company_user_last_message_read" : "owner_last_message_read"]= 0
    
            const createMessageResult = await API.graphql(
                graphqlOperation(createMessage, { input: createMessageInput })
            );
            onMessageSentHandler(createMessageResult.data.createMessage)
            await API.graphql(
                graphqlOperation(updateChat, { input: updateChatInput })
            );
            setInputText("")
            setSendingMessage(false)
        }catch(e){
            console.error(e);
            if(e === 'No current user'){
                window.location.reload(false);
            }
        }
    }

    async function handleAttachmentSubmit() {
        if(!attachment) return;
        let attachmentKey = `chat/${chat.id}/attachments/${Date.now()}/${attachment.name}`;
        setSendingMessage(true)
        //const attachmentUploadFileResponse = await Storage.put(attachmentKey, attachment, { progressCallback: sendingMessageProgressCallback });

        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        //let croppedImageData = await fetch(attachment);
        //let croppedImageBlob = await croppedImageData.blob();

        //const base64Data = await blobToBase64(attachment);
        //const base64Content = base64Data.split(',')[1];
        const response = await fetch(FILES_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(
                {
                fileName: attachmentKey,
                fileType: attachment.type,
                file: base64Attachment
            })
        });
        const data = await response.json();
        console.log(data);
        

        const attachmentS3File = {
            key: attachmentKey,
            bucket: aws_exports.aws_user_files_s3_bucket,
            region: aws_exports.aws_project_region
        };

        const createMessageInput= {
            chat_id: chat.id,
            messageChatId: chat.id,
            from: user.cognitoId,
            to: toCognitoId,
            message: attachment.name,
            message_type: "attachment",
            file: attachmentS3File
        }
        
        const updateChatInput= {
            id: chat.id,
            last_message: attachment.name,
        }
        updateChatInput[chat.chat_type === "S" ? "owner_last_message_read" : "company_user_last_message_read"]= 1
        updateChatInput[chat.chat_type === "S" ? "company_user_last_message_read" : "owner_last_message_read"]= 0

        const createMessageResult = await API.graphql(
            graphqlOperation(createMessage, { input: createMessageInput })
        );
        onMessageSentHandler(createMessageResult.data.createMessage)
        await API.graphql(
            graphqlOperation(updateChat, { input: updateChatInput })
        );
        setAttachment(null)
        setSendingMessage(false)
    }

    function sendingMessageProgressCallback(progress) {
        if(progress.loaded === progress.total) {
            setSendingMessage(false)
        }
    }

    function onChangeAttachmentInput(event) {
        event.stopPropagation()
        event.preventDefault()
        var file = event.target.files[0]
        console.log(file)
        setAttachment(file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const base64String = e.target.result.split(',')[1]; // Extract base64 string
              setBase64Attachment(base64String);
            };
            reader.readAsDataURL(file);
          }
    }

    function onMessageSentHandler(message) {
        if(onMessageSent)
            onMessageSent(message)
    }

    function handleAttachmentCancel() {
        setAttachment(null)
    }

    const handleKeyDown = (event) => {
        //console.log('A key was pressed', event.keyCode + ' ' + event.charCode);
        if (event.charCode === 13 || event.keyCode === 13) {
            event.preventDefault();
            handleTextMessageSubmit();
        }
    };
    //TODO mover esta logica a un punto central porque se manda llamar tambien de otro lugar =(
    const handleCreateOrder = async () => {
        console.log(`creating order`);
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const resultOrders = await API.graphql({ query: maxOrderNumberQuery, variables: { filter: { owner: { eq: user.username } }  }});
        console.log({resultOrders});
        let maxNumber = 10;
        if(resultOrders.data.listOrders.items && resultOrders.data.listOrders.items.length > 0){
            console.log('si hay items, recorriendo para obtener uno nuevo');
            maxNumber = Math.max.apply(Math, resultOrders.data.listOrders.items.map(function(o) { return o.orderNumber; })) + 1;
        }

        const companyResult  = await API.graphql({
            query: listCompanys,
            variables: { filter: { owner: {eq: user.username } } }
        });
        const company = companyResult.data.listCompanys.items[0];
        const filter = {
            cognito_id : {eq : company.owner}
        }
        const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter }});
        console.log({resultCompanyUser});
        const _user = resultCompanyUser.data.listUsers.items[0]
        const input = {
            owner: user.username,
            owner_name: _user.first_name + ' ' + _user.last_name,
            buyer:'',
            buyer_name:'',
            buyer_email: '',
            orderNumber: maxNumber,
            company_name: company ? company.name : '',
            company_id: company ? company.id : '',
            company_contact_name: _user.first_name + ' ' + _user.last_name,
            company_email: _user.email,
            company_address: company ? company.address_str : '',
            seller_status: 0,
            buyer_status: 0,
            total: 0
        };
        const result = await API.graphql(
            graphqlOperation(createOrder, { input })
        );
        console.log({result});
        setLoading(false);
        const isFirefox = typeof InstallTrigger !== 'undefined';
        console.log('isFirefox ' , isFirefox);
        if(isFirefox){
            history.push("/add-order-wizard/"+result.data.createOrder.id);
        }else{
            const win = window.open("/add-order-wizard/"+result.data.createOrder.id, "_blank");
            win.focus();
        }
     }

    return (
        <Layout.Row className="chat-messages-inputs p-md">
            <Layout.Col span={chat.chat_type === "S" ? 15 : 17} className="p-none m-none">
                {
                    attachment ? 
                        <div className="chat-attachment-message-body p-l-md chat-messages-input-attachment-selected" >
                            <FiFile size={28} className="m-r-sm" /> {attachment.name}
                        </div>
                    :
                    <Input onKeyPress={handleKeyDown} placeholder={t('chat.input.text-input-placeholder')} onChange={(e) => setInputText(e) } value={inputText} />
                    
                }
            </Layout.Col>
            <Layout.Col span={chat.chat_type === "S" ? 9 : 7} className="p-none m-none align-center">
                {
                    sendingMessage ?
                        <>
                            <Button className="primary-button chat-input-button m-none m-r-xs m-l-sm p-sm p-l-sm p-r-md" loading={sendingMessage}>
                                {t('chat.input.sending-message')}
                            </Button>
                        </>
                    :attachment ? 
                        <>
                            <Button className="primary-button chat-input-button m-none m-r-xs m-l-sm p-sm p-l-sm p-r-md" onClick={handleAttachmentSubmit}>
                                <FiSend className="p-r-xxs" /> {t('chat.input.send-message')}
                            </Button>
                            <Button className="primary-button chat-input-button m-none m-r-xs m-l-sm p-sm p-l-sm p-r-md" onClick={handleAttachmentCancel}>
                                <FiX className="p-r-xxs" /> {t('chat.input.cancel-attachment')}
                            </Button>
                        </>
                    : 
                        <>
                            <Button className="primary-button chat-input-button m-none m-r-xs m-l-sm p-sm p-l-sm p-r-md" onClick={handleTextMessageSubmit}>
                                <FiSend className="p-r-xxs" /> {t('chat.input.send-message')}
                            </Button>
                            <Button className="primary-button chat-input-button m-none m-l-xs p-sm p-l-sm p-r-md" onClick={() => attachmentInput.current.click()}>
                                <ImAttachment color="white" /> {t('chat.input.attachment')} 
                                <input type='file' id='file' accept="image/*, .csv, .pdf" ref={attachmentInput} style={{display: 'none'}} onChange={onChangeAttachmentInput}/>
                            </Button>
                            {
                                chat.chat_type === "S" 
                                ?
                                    <Button loading={loading} className="primary-button chat-input-button m-none m-l-xs p-sm p-l-sm p-r-md" onClick={() => handleCreateOrder()}>
                                    <FiBook className="p-r-xxs" /> {t('chat.input.new-order')}
                                    </Button>
                                :
                                    <></>
                            }
                            
                            
                        </>
                }
            </Layout.Col>
        </Layout.Row>
    )
}
