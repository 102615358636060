import { Layout, Card , Button, Dialog, Input, Checkbox, Tabs, Message, Notification} from 'element-react';
import { useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { S3Image } from "../components/S3Image";
import {toCurrency} from '../utils/FormatUtils';
import StarFilledIcon from '../components/StarFilledIcon';
import {yearsFromToday} from '../utils/FormatUtils';
import aboutUsImg from '../assets/imgs/about-us-default.png';
import { useHistory } from "react-router-dom";
import {API, graphqlOperation } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import {listUserFavoritess, listUsers} from '../graphql/queries';
import {createUserFavorites, deleteUserFavorites} from '../graphql/mutations';
import {generateProductChat, generateProductChateFromInput} from '../services/ChatService';
import {CHAT_PRODUCT_MESSAGE} from '../utils/ChatConstants';
import {IoIosChatbubbles} from "react-icons/io";
import sendEmailTemplate from '../graphql/lambdaInvokes';
import userStore from '../store/user';
import * as Constans from '../../src/utils/Constants';
import WarningModal from '../components/WarningModal';
import PagerQuery from '../utils/PagerQuery';

//import userStore from '../store/user';
function ProductView(props){
    const { t, i18n } = useTranslation();
    const [warningModal, showWarningModal] = useState(false);
    //const {user, setUserInfo, cleanUser} = userStore();
    const {productPage, mainPhoto, handleChangeMainPhoto, setLoading, userOwner} = props;
    const [showImageDialog, setShowImageDialog] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const [favoriteId, setFavoriteId] = useState(null);
    const [modalKey, setModalKey] = useState({key : ''});
    const [favoriteLoading, setFavoriteLoading] = useState(false);
    const [callmeStatus, setCallmeStatus] = useState(false);
    const [sendMsgStatus, setSendMsgStatus] = useState(false);
    const [questionInput, setQuestionInput] = useState('');
    const [checkStatus, setCheckStatus] = useState(true);
    const {user} = userStore();
    let history = useHistory();
    const showImageModal = (key) =>{
        setShowImageDialog(true);
        setModalKey({key});
    }
    const goToCompany = (id) =>{
        console.log(`goToCompany ==> ${id}`);
        history.push("/company-store/"+id);
    }
    const addToFavorites = async () =>{
        console.log('addToFavorites');
        try{
            if(favorite === false){
                setLoading(true);
                setFavoriteLoading(true);
                const u = await Auth.currentAuthenticatedUser();
                console.log({u});
                console.log('Adding to favorites prduct :: ' + productPage.id + ' :: ' + productPage.name);
                const input = {
                    userFavoritesProductId : productPage.id
                }
                const result = await API.graphql(
                    graphqlOperation(createUserFavorites, { input })
                );
                console.log(result);
                setFavorite(true);
                //@ts-ignore
                setFavoriteId(result.data.createUserFavorites.id);
                setLoading(false);
                setFavoriteLoading(false);
            }
            
        }catch(e){
            console.error(e);
            setLoading(false);
            setFavoriteLoading(false);
            if(e === 'The user is not authenticated'){
                console.log('Alert to user');
                Message({
                    message: t('product.create.preview.no-session'),
                    type: 'error'
                });
                history.push('/login');
            }
        }
        
    }

    const removeFromFavorites = async() =>{
        console.log('removeFromFavorites');
        try{
            setLoading(true);
            setFavoriteLoading(true);
            const input = {
                id : favoriteId
            }
            const result = await API.graphql(
                graphqlOperation(deleteUserFavorites, { input })
            );
            console.log(result);
            setFavorite(false);
            setFavoriteId(null);
            setLoading(false);
            setFavoriteLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
            setFavoriteLoading(false);
        }
    }

    useEffect(() => {
        getFavorites();
    }, [productPage]);

    const getFavorites = async () =>{
        console.log('getFavorites');
        try{
            setFavoriteLoading(true);
            const u = await Auth.currentAuthenticatedUser();
            console.log({u});
            
            let filter = {
                owner: {eq: u.username}
            };
            const result = await API.graphql({ query: listUserFavoritess, variables: { filter}});
            console.log({result});
            const arrayData = result.data.listUserFavoritess.items;
            arrayData.forEach(element => {
                console.log({element});
                console.log('element.product.id :: ' + element.product.id);
                if(element.product.id === productPage?.id){
                    setFavorite(true);
                    //@ts-ignore
                    setFavoriteId(element.id);
                }
            });
            setFavoriteLoading(false);
        }catch(e){
            console.error(e);
            setFavoriteLoading(false);
        }
        
    }

    const handleChatWithUs = async() =>{
        console.log('handletChatWithUs');
        if(user.type === "XYZ"){
            console.log('user seller cannot init a chat');
            Message({
                message: t('product.create.preview.error-seller'),
                type: 'error'
            });
            return;
        }
        setLoading(true);
        try{
            console.log({user})
            const filter = {
                cognito_id : {eq : productPage.owner}
            }
            //const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter}});
            const input = { filter: filter }
            const pagerQuery = new PagerQuery(listUsers, input, Object.keys({ listUsers }));
            const resultCompanyUser = await pagerQuery.searchPrivateData();
            console.log({resultCompanyUser});
            const chatResult  = await generateProductChat(CHAT_PRODUCT_MESSAGE, productPage, t, user, resultCompanyUser.data.listUsers.items[0]);
            if(chatResult === true){
                history.push("/my-chats/1");
            }
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.log({e});
            if(e == 'Error: No current user' || e === 'The user is not authenticated'){
                console.log('Alert to user');
                history.push('/login');
            }
        }
        
    } 
    const callMe = async() =>{
        try{
            setLoading(true);
            const u = await Auth.currentAuthenticatedUser();
            console.log({u});
            console.log('i18n.languagei18n.language :: ' + i18n.language);
            await sendEmailTemplate({
                templateName: "callMe", 
                subject: i18n.language == "en" ? "You have received a Call Me request in Bainub" : "Has recibido una solicitud de Contacto en Bainub",
                emailFrom: Constans.FROM_EMAIL,
                recipients: Constans.USE_PRD_EMAIL ? [userOwner.username]  : ["victor_calva@mincovac.com", "eskasu@gmail.com"], 
                templateVars: {
                    username: props.companyPage?.name
                },
                templateLanguage: i18n.language  == "en" ? "en" : "es"
            });
            
            const filter = {
                cognito_id : {eq : productPage.owner}
            }
            const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter}});
            console.log({resultCompanyUser});
            await generateProductChateFromInput(questionInput, productPage, t, user, resultCompanyUser.data.listUsers.items[0], checkStatus);

            Notification({
                title: 'Success',
                message: t('product.create.preview.call-me-message'),
                type: 'success'
            });
            setCallmeStatus(true);
            setLoading(false);
        
            
            
        }catch(e){
            setLoading(false);
            console.error(e);
            if(e === 'The user is not authenticated'){
                console.log('Alert to user');
                Message({
                    message: t('product.create.preview.no-session'),
                    type: 'error'
                });
                history.push('/login');
            }
        }
    }

    const sendMessage = async() =>{
        try{
            if(user.type === "XYZ"){
                console.log('user seller cannot init a chat');
                Message({
                    message: t('product.create.preview.error-seller'),
                    type: 'error'
                });
                return;
            }
            setLoading(true);
            const u = await Auth.currentAuthenticatedUser();
            console.log({u});
            const filter = {
                cognito_id : {eq : productPage.owner}
            }
            const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter}});
            console.log({resultCompanyUser});

            const resultChat = await generateProductChateFromInput(questionInput, productPage, t, user,resultCompanyUser.data.listUsers.items[0], checkStatus);
            
            if(resultChat){
                await sendEmailTemplate({
                    templateName: "callMe", 
                    subject: i18n.language == "en" ? "You have received a Call Me request in Bainub" : "Has recibido una solicitud de Contacto en Bainub",
                    emailFrom: "eskasu@gmail.com",
                    recipients: Constans.USE_PRD_EMAIL ? [userOwner?.username]  : ["victor_calva@mincovac.com", "eskasu@gmail.com"], 
                    templateVars: {
                        fullname: props.companyPage?.name
                    },
                    templateLanguage: i18n.language  == "en" ? "en" : "es"
                });
                setSendMsgStatus(true);
                setQuestionInput('');

                Notification({
                    title: 'Success',
                    message: t('product.create.preview.call-me-message'),
                    type: 'success'
                });
            }

            setLoading(false);
            
            
        }catch(e){
            setLoading(false);
            console.error(e);
            if(e === 'The user is not authenticated' || e === 'No current user'){
                console.log('Alert to user');
                Message({
                    message: t('product.create.preview.no-session'),
                    type: 'error'
                });
                history.push('/login');
            }
        }
    }
    

    return(
        <>
            <WarningModal
                modalWarningVisible={warningModal}
                setModalWarningVisible={showWarningModal}
                action={()=>callMe()}
                title={t('modal.callme.title')}
                message={t('modal.callme.message1')}
                message2={t('modal.callme.message2')}
                message3={t('modal.callme.message3')}
            />

            <Dialog size="large"
                visible={showImageDialog}
                onCancel={ () => { 
                    console.log('cancel Diaglog');
                    setShowImageDialog(false);
                } }
            >
                <Dialog.Body className="text-center">
                    
                        {
                            modalKey.key 
                            ?
                                <S3Image
                                    imgKey={modalKey.key}
                                    theme={{
                                        photoImg: { maxWidth: "70vw", maxHeight: "70vh", marginRight: "15px"}
                                    }}
                                    level="public"/>
                            :
                                <></>
                        }
                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">
                    <form-button-inverse style={{'padding-right' : '10px'}}>
                        <Button onClick={ () => { 
                            setShowImageDialog(false); 
                        }  
                        }>{'Close'}</Button>
                    </form-button-inverse>
                </Dialog.Footer>
            </Dialog>
                
                
                <Layout.Row type="flex">
                    <Layout.Col span="24" xs="20" sm="20" lg="24">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="12">
                    <Layout.Row type="flex">
                        <Layout.Col span="24" xs="20" sm="20" lg="4">
                            {
                                productPage &&
                                productPage.photo &&
                                productPage.photo.map((element, i) =>{
                                    return(
                                        <>
                                            {
                                                i%2 == 0 
                                                ?
                                                    <S3Image 
                                                    onClick={() => {handleChangeMainPhoto(element.key)}}
                                                    className="photo-card"
                                                    key={i}
                                                    imgKey={element.key}
                                                    theme={{
                                                        photoImg: { cursor: 'pointer', maxWidth: "75px", maxHeight: "75px", marginRight: "5px"}
                                                    }}
                                                    level="public"/>
                                                :
                                                    <></>
                                            }
                                            
                                        </>
                                    )
                                })
                            }
                        </Layout.Col>
                        <Layout.Col span="24" xs="20" sm="20" lg="4">
                            {
                                productPage &&
                                productPage.photo &&
                                productPage.photo.map((element, i) =>{
                                    return(
                                        <>
                                            {
                                                i%2 != 0 
                                                ?
                                                    <S3Image 
                                                    onClick={() => {handleChangeMainPhoto(element.key)}}
                                                    className="photo-card"
                                                    key={i}
                                                    imgKey={element.key}
                                                    theme={{
                                                        photoImg: { cursor: 'pointer', maxWidth: "75px", maxHeight: "75px", marginRight: "5px"}
                                                    }}
                                                    level="public"/>
                                                :
                                                    <></>
                                            }
                                            
                                        </>
                                    )
                                })
                            }
                        </Layout.Col>
                        <Layout.Col span="24" xs="20" sm="20" lg="20">
                                <S3Image 
                                    className="photo-card"
                                    imgKey={mainPhoto ? mainPhoto : (productPage ? (productPage.photo && productPage.photo.length > 0 ? productPage.photo[0].key : '') : '')}
                                    theme={{
                                        photoImg: { maxWidth: "440px", maxHeight: "440px", marginRight: "5px"}
                                    }}
                                    level="public"/>
                        </Layout.Col>
                    </Layout.Row>
                        
                    </Layout.Col>
                    <Layout.Col span="24"xs="20" sm="20" lg="9">
                        <Card className="box-card max-img-prewiew">
                            <Layout.Row type="flex">
                                <Layout.Col tag="display-product-info" span="24" xs="20" sm="20" lg="24">
                                    
                                    <h3 style={{lineHeight: "normal"}} >{productPage?.name}</h3>
                                    <div style={{display:'inline-flex'}}>
                                        <p>by:&nbsp;</p>
                                        {
                                            props.companyPage ?
                                                <p onClick={() => {goToCompany(props.companyPage.id)}} className="cursor link-company-name">{props.companyPage?.name}</p>
                                            :
                                                <></>
                                        }
                                        
                                    </div>
                                    <br/>
                                    <div style={{display:'inline-flex'}}>
                                        <p>{t('product.create.preview.price-range')}:&nbsp;</p><p className="product-info-values">{toCurrency(productPage?.min_price)} - {toCurrency(productPage?.max_price)}</p>
                                    </div>
                                    <br/>
                                    <div style={{display:'inline-flex'}}>
                                        <p>{t('product.create.preview.min-order')}:&nbsp;</p><p className="product-info-values">{productPage?.min_order}</p>
                                    </div>
                                    <br/>
                                    <div style={{display:'inline-flex'}}>
                                        <p>{t('product.create.preview.custom-allowed')}:&nbsp;</p><p className="product-info-values">{productPage?.customizable == 'true' ? "Yes" : "No"}</p>
                                    </div>
                                    <br/>
                                    <div style={{display:'inline-flex'}}>
                                        <p>{t('product.create.preview.package')}:&nbsp;</p><p className="product-info-values">{productPage?.package_type}</p>
                                    </div>
                                    <br/>
                                    <div style={{display:'inline-flex'}}>
                                        <p>{t('product.create.preview.warranty')}:&nbsp;</p><p className="product-info-values">{productPage?.warranty_time}</p>
                                    </div>
                                    <br/>
                                    <div style={{display:'inline-flex'}}>
                                        <p>{t('product.create.preview.contact-me')}</p>
                                    </div>
                                </Layout.Col>
                            </Layout.Row>
                            
                                 {/*
                                <Layout.Row type="flex" justify="center">
                                    <Layout.Col tag="form-button" span="24" xs="20" sm="20" lg="24">
                                        <Button className="pp-info-button" type="primary" disabled={callmeStatus} onClick={()=>showWarningModal(true)}>
                                            {t('product.create.preview.button-callme')}
                                        </Button>
                                    </Layout.Col>
                                </Layout.Row>
                                */}
                                <Layout.Row type="flex" justify="center">
                                    <Layout.Col className="pp-separator" span="24" xs="20" sm="20" lg="24">
                                        &nbsp;
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row type="flex" justify="center">
                                    <Layout.Col tag="form-button" span="24" xs="20" sm="20" lg="24">
                                        
                                            <Button className="pp-info-button" onClick={handleChatWithUs} type="primary">
                                                    <IoIosChatbubbles className="p-r-xxs"/> {t('product.create.preview.button-chat')}
                                            </Button>
                                        
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row type="flex" justify="center">
                                    <Layout.Col className="pp-separator" span="24" xs="20" sm="20" lg="24">
                                        &nbsp;
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row type="flex" justify="center">
                                    <Layout.Col tag="form-button-inverse" span="24" xs="20" sm="20" lg="24">
                                        <Button loading={favoriteLoading} onClick={favorite === true ? removeFromFavorites : addToFavorites} className="pp-info-button" type="primary">
                                            {
                                                favorite === true 
                                                ?
                                                    <><StarFilledIcon width={15} height={15}/>{t('product.create.preview.button-remove-favorites')}</>
                                                :
                                                    <><StarFilledIcon width={15} height={15}/>{t('product.create.preview.button-add-favorites')}</>
                                            }
                                            
                                        </Button>
                                    </Layout.Col>
                                </Layout.Row>
                        
                            
                        </Card>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="21">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="21">
                        <Card className="box-card">
                        <h3>{t('product.create.preview.general-info')}</h3>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.brand')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.int-ext')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.size')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.special-functions')}</p>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.brand ? productPage.brand : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.int_ext ? productPage.int_ext : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.size ? productPage.size : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.special_functions ? productPage.special_functions : "-"}
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.color')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.titles')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.life-time')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.material')}</p>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.color ? productPage.color : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.titles ? productPage.titles : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.life_time ? productPage.life_time : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.material ? productPage.material : "-"}
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.voltage')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.power')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.form.protection')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    &nbsp;
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.voltage ? productPage.voltage : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.power ? productPage.power : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.protection ? productPage.protection : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    &nbsp;
                                </Layout.Col>
                            </Layout.Row>
                        </Card>
                    </Layout.Col>
                </Layout.Row>

                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="21">
                        <Card className="box-card">
                        <h3>{t('product.create.preview.technical-info')}</h3>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="24">
                                    <div className="jodit-dialog__content" dangerouslySetInnerHTML={{__html: (productPage && productPage.big_description ? productPage.big_description : "-")}}/>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                    &nbsp;
                                </Layout.Col>
                            </Layout.Row>
                            
                            {
                                productPage &&
                                productPage.file.map((element, i) =>{
                                    return(
                                        <Layout.Row type="flex">
                                        <Layout.Col className="text-left" span="24" offset="1" xs="22" sm="22" lg="22">
                                                    <S3Image 
                                                    key={i}
                                                    imgKey={element.key}
                                                    theme={{
                                                        photoImg: { maxWidth: "70vw"}
                                                    }}
                                                    level="public"/>
                                        </Layout.Col>
                                        </Layout.Row>
                                    )
                                })
                            }
                            
                        </Card>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="21">
                        <Card className="box-card">
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                    &nbsp;
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                            {
                                productPage && productPage.addtional_photo &&
                                productPage.addtional_photo.map((element, i) =>{
                                    return(
                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="4">
                                                    <S3Image 
                                                    onClick={() => {showImageModal(element.key)}}
                                                    className="photo-card"
                                                    key={i}
                                                    imgKey={element.key}
                                                    theme={{
                                                        photoImg: { cursor: 'pointer', maxWidth: "100px", maxHeight: "100px", marginRight: "5px"}
                                                    }}
                                                    level="public"/>
                                        </Layout.Col>
                                    )
                                })
                            }
                            </Layout.Row>
                        </Card>
                    </Layout.Col>
                </Layout.Row>

                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="21">
                        <Card className="box-card">
                        <h3>{t('product.create.preview.package-info')}</h3>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.preview.package-type')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                <p className="detail-sub-title">{t('product.create.preview.quantity-per-package')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                <p className="detail-sub-title">{t('product.create.preview.package-weight')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                <p className="detail-sub-title">{t('product.create.preview.package-dimensions')}</p>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.package_type ? productPage.package_type : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.quantity_per_package ? productPage.quantity_per_package : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.package_weight ? productPage.package_weight : "-"}&nbsp;kg
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.package_dimensions ? productPage.package_dimensions : "-"}&nbsp;mm
                                </Layout.Col>
                            </Layout.Row>
                        </Card>
                    </Layout.Col>
                </Layout.Row>

                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="21">
                        <Card className="box-card">
                        <h3>{t('product.create.preview.delivery-info')}</h3>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.preview.min-order')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                <p className="detail-sub-title">{t('product.create.preview.monthly-capacity')}</p>
                                </Layout.Col>
                                {
                                    /*
                                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.preview.production-type')}</p>
                                    </Layout.Col>
                                    */
                                }
                                
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                <p className="detail-sub-title">{t('product.create.preview.warranty')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    <p className="detail-sub-title">{t('product.create.preview.client-service')}</p>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.min_order ? productPage.min_order : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.monthly_capacity ? productPage.monthly_capacity : "-"}
                                </Layout.Col>
                                {
                                    /* 
                                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.z ? productPage.z : "-"}
                                    </Layout.Col>
                                    */
                                }
                                
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.warranty_time ? productPage.warranty_time : "-"}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    { productPage && productPage.client_service ? productPage.client_service : "-"}
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    &nbsp;
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    &nbsp;
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    &nbsp;
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    &nbsp;
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    &nbsp;
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="6">
                                    &nbsp;
                                </Layout.Col>
                            </Layout.Row>
                        </Card>
                    </Layout.Col>
                </Layout.Row>


                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="21">
                        <Card className="box-card">
                        <h3>{t('product.create.preview.about-us')}</h3>
                        <Tabs type="card" value="1">
                            <Tabs.Pane label={t('product.create.preview.about-us')} name="1">

                            <div class="special-container">
	
                                    {
                                            props.companyPage && props.companyPage.company_logo
                                            ?
                                                <S3Image
                                                    className="special-container__image"
                                                    imgKey={props.companyPage.company_logo.key}
                                                    theme={{
                                                        photoImg: { maxWidth: "50vw" }
                                                    }}
                                                    level="public"
                                                />
                                            :
                                                <img className="special-container__image" src={aboutUsImg} style={{ maxWidth: "650px", maxHeight: "335px", minHeight: "235px" }}/>
                                        }
                                <div class="special-container__text">
                                    <span className='description'>{props.companyPage? props.companyPage.description : ""}</span>
                                </div>
                            </div>
                            </Tabs.Pane>
                            <Tabs.Pane label={t('product.create.preview.additionla-info')} name="2">
                                        <Layout.Row type="flex">
                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                                            
                                            <Layout.Row type="flex">
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    <p className="detail-sub-title">{t('my-store.page.business-type')}</p>
                                                </Layout.Col>
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    <p className="detail-sub-title">{t('my-store.page.member-since')}</p>
                                                </Layout.Col>
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    <p className="detail-sub-title">{t('my-store.page.year-stablished')}</p>
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    {props.companyPage ? props.companyPage.type : ""}
                                                </Layout.Col>
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    {props.companyPage ? yearsFromToday(props.companyPage.createdAt): ""} years
                                                </Layout.Col>
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    {props.companyPage ? props.companyPage.age : ""}
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="7">
                                                    <p className="detail-sub-title">{t('my-store.page.city')}</p>
                                                </Layout.Col>
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="4">
                                                    <p className="detail-sub-title">{t('my-store.page.annual-revenue')}</p>
                                                </Layout.Col>
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    <p className="detail-sub-title">{t('my-store.page.allow-custom-prd')}</p>
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    {props.companyPage ? props.companyPage.region : ""}
                                                </Layout.Col>
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    {props.companyPage ? props.companyPage.anual_revenue : ""}
                                                </Layout.Col>
                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                    {props.companyPage ? (props.companyPage.allow_custom_product == 1 ? 'Yes' : 'No') : ""}
                                                </Layout.Col>
                                                
                                            </Layout.Row>
                                        
                                    </Layout.Col>
                                </Layout.Row>
                            </Tabs.Pane>
                        </Tabs>
                        </Card>
                    </Layout.Col>
                </Layout.Row>

                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="22">
                            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="22">
                                    <h2 className="pp-line-height primary-color">{t('product.create.preview.contact-us')}</h2>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="21">
                                    <p className="pp-line-height">{t('product.create.preview.contact-us-inquiry')}</p>
                                </Layout.Col>
                            </Layout.Row>
                                
                            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="23">
                                    <Input
                                        type="textarea"
                                        value={questionInput}
                                        onChange={(v)=>{setQuestionInput(v)}}
                                        autosize={{ minRows: 8, maxRows: 8}}
                                    />
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex" >
                                <Layout.Col span="24" xs="20" sm="20" lg="21">
                                    <Checkbox 
                                        checked={checkStatus} 
                                        onChange={(v)=>{console.log(v); setCheckStatus(v)}}
                                    >{t('product.create.preview.contact-us-permission')}</Checkbox>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="23">
                                    <Layout.Row type="flex" justify="end">
                                        <Layout.Col tag="form-button">
                                        
                                            <Button type="primary" disabled={sendMsgStatus} onClick={sendMessage}>
                                                    {t('product.create.preview.button-send')}
                                            </Button>
                                        
                                        </Layout.Col>
                                    </Layout.Row>
                                </Layout.Col>
                            </Layout.Row>
                    </Layout.Col>
                </Layout.Row>
                
                            

        </>
    )
}
export default ProductView;