import React, {useRef, useState, useEffect} from 'react'
import {Button, Loading, Form, Input, Layout, Notification, Select} from 'element-react';
import { useTranslation } from 'react-i18next';
import * as surveyController from "../graphql/surveyLambdaInvokes";
import surveyFormRules from '../utils/SurveyFormRules';
import {countryOptions} from '../utils/Catalogs';
import sendEmailTemplate from '../graphql/lambdaInvokes';
import * as Constans from '../../src/utils/Constants';


export default function Survey( { surveyTitle, formType }) {
    const { t } = useTranslation();

    const form = useRef(null);
    const [loading, setLoading] = useState(false);
    const [country] = useState(countryOptions);
    const [surveyRules, setSurveyRules] = useState(null);
    const [formInputs, setFormInputs] = useState(
        {
            fullname : '',
            email: '',
            phone : '',
            products : '',
            country: '',
            company_name: '',
            is_seller: false
        }
    );

    useEffect(() => {
        setSurveyRules(surveyFormRules(t));
    }, [t]);


    const submitHandler = () => {
        form.current.validate((valid) => { 
            if (valid) {
                handleOnSubmit();
            }
        });
    }

    const handleOnSubmit = async () => {
        try {
            console.log('saving survey');
            setLoading(true);
            var saveSurveyResponse = await surveyController.saveSurvey({
                name: formInputs.fullname,
                mail: formInputs.email,
                phone: formInputs.phone,
                product_request: formInputs.products,
                form_type: formType,
                is_seller: formInputs.is_seller ? 1 : 0,
                company_name: formInputs.company_name,
                country: formInputs.country
            });

            await sendEmailTemplate({
                templateName: "form", 
                subject: 'FORMULARIO LET US HELP',
                emailFrom: Constans.FROM_EMAIL,
                recipients: [ "customerservice@bainub.com" ],
                //recipients: [ "czuniga@automateit.mx" ],
                templateVars: {
                    content:  formInputs.fullname 
                    + "<br/>" + formInputs.email
                    + "<br/>" + formInputs.phone
                    + "<br/>" + formInputs.products
                    + "<br/>" + formInputs.is_seller
                    + "<br/>" + formInputs.company_name
                    + "<br/>" + formInputs.country
                    + "<br/>" + new Date().toLocaleString("en-US", {timeZone: "America/Mexico_City"})
                },
                templateLanguage: "en"
            });

            console.log(saveSurveyResponse);
            setLoading(false);
            if(saveSurveyResponse.returnCode === 0) {
                console.log("survey saved successfully");
                clearForm();
                Notification({
                    title: t('home-page.product-survey.confirmation-title'),
                    message: t('home-page.product-survey.confirmation-message'),
                    type: 'success',
                    offset: 100
                });
            } else {
                console.log("survey save failed");
                Notification({
                    title: t('home-page.product-survey.confirmation-error-title'),
                    message: t('home-page.product-survey.confirmation-error-message'),
                    type: 'error',
                    offset: 100
                });
            }
        } catch (e) {
            console.error(e)
        }
    }

    const clearForm = () => {
        setFormInputs({
            fullname : '',
            email: '',
            phone : '',
            products : '',
            country: '',
            company_name: '',
            is_seller: false
        });
    }

    const renderCountries = country.options.map(el => <Select.Option key={el.value} value={el.value} label={el.label}/>)
    
    let dialog =
    <>
        {
            loading && <Loading loading={loading} fullscreen={true} />
        }
        <Form ref={form} model={formInputs} rules={surveyRules} >
            <Layout.Row >
                <Layout.Col span="24" offset="1" xs="22" sm="22" md="22" lg="22">
                    <label className="welcome-banner-card-title mb-1"><b>{surveyTitle}</b></label>
                    <p class="welcome-banner-card-text">Share your interests and we’ll contact you with useful information from potential partners.</p>
                    <Form.Item label={t('home-page.product-survey.is_seller')} prop="is_seller" className="mb-0 inline-form mr-1">
                        <Select 
                            value={formInputs.is_seller} 
                            placeholder={t('home-page.product-survey.is_seller')}
                            onChange={(v) => { setFormInputs({...formInputs, is_seller: v}); }} 
                            filterable={false} >
                            <Select.Option key={false} value={false} label="Buyer"/>
                            <Select.Option key={true} value={true} label="Seller"/>
                        </Select>
                    </Form.Item>
                    <Form.Item prop="fullname" className="mb-0 mt-3 inline-form">
                        <Input 
                            value={formInputs.fullname} 
                            placeholder={t('home-page.product-survey.fullname')}
                            onChange={(v) => {setFormInputs({...formInputs, fullname: v})}} />
                    </Form.Item>
                    <Form.Item prop="products" className="mb-0 mt-3">
                        <Input 
                            value={formInputs.products}
                            placeholder={t('home-page.product-survey.products')}
                            onChange={(v) => {setFormInputs({...formInputs, products: v})}} />
                    </Form.Item>
                    <Form.Item prop="email" className="mb-0 mt-3 mr-1 inline-form">
                        <Input 
                            value={formInputs.email}
                            placeholder={t('home-page.product-survey.email')}
                            onChange={(v) => {setFormInputs({...formInputs, email: v})}} />
                    </Form.Item>
                    <Form.Item prop="phone" className="mb-0 mt-3 inline-form">
                        <Input
                            value={formInputs.phone}
                            placeholder={t('home-page.product-survey.phone')}
                            onChange={(v) => {setFormInputs({...formInputs, phone: v})}} />
                    </Form.Item>
                    <Form.Item prop="company_name" className="mb-0 mt-3 mr-1 inline-form">
                        <Input 
                            value={formInputs.company_name}
                            placeholder={t('home-page.product-survey.company_name')}
                            onChange={(v) => {setFormInputs({...formInputs, company_name: v})}} />
                    </Form.Item>
                    <Form.Item prop="country" className="mt-3 inline-form">
                        <Select 
                            value={formInputs.country} 
                            placeholder={t('register-form.country-placeholder')}
                            onChange={(v) => { setFormInputs({...formInputs, country: v}); }} 
                            filterable={false} >
                            {renderCountries}
                        </Select>
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row type="flex" justify="end" className="ml-2 mr-2">
                <Layout.Col tag="form-button" pull="1" xs="20" sm="20" lg="20" span="30">
                     <p class="welcome-banner-card-text-privacy no-gutter">By using form, I agree with Terms of Use and the Privacy Policy to receive emails from Bainub.</p>
                    <Form.Item className="pt-2">
                        <Button type="primary" onClick={submitHandler} loading={loading} >
                            {t('home-page.product-survey.submit-button')}
                        </Button>
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
        </Form>
    </>;
    return dialog;
}