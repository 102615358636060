import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";

class PagerQuery{
    finalArrayData = null;
    constructor(query, input, methodName){
        this.query = query;
        this.input = input;
        this.methodName = methodName;
        this.originalLimit = input.limit;
    }

    async searchPublicData(){
        console.log('searching data...');
        
        const result  = await API.graphql({
            query: this.query,
            variables: this.input,
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM
        });
        console.log("====================");
        console.log({result});
        console.log("Size :: " + result.data[this.methodName.toString()].items.length);
        console.log("====================");
        

        if(this.finalArrayData === null){
            this.finalArrayData = result;
        }else{
            result.data[this.methodName.toString()].items.forEach(element => {
                this.finalArrayData.data[this.methodName.toString()].items.push(element);
            });
            this.finalArrayData.data[this.methodName.toString()].nextToken = result.data[this.methodName.toString()].nextToken;
        }
        console.log('final Size :: ' + this.finalArrayData.data[this.methodName.toString()].items.length);
        console.log('this.originalLimit :: ' + this.originalLimit);
        console.log('this.finalArrayData.data[this.methodName.toString()].items.length < this.originalLimit :: ' + this.finalArrayData.data[this.methodName.toString()].items.length < this.originalLimit);
        if(this.finalArrayData.data[this.methodName.toString()].items.length < this.originalLimit && result.data[this.methodName.toString()].nextToken !== null){
            this.input.limit = this.originalLimit - this.finalArrayData.data[this.methodName.toString()].items.length;
            this.input.nextToken = result.data[this.methodName.toString()].nextToken;
            await this.searchPublicData();
        }
        return  this.finalArrayData;
    }
    
    async searchPrivateData(){
        console.log('searching data...');
        
        const result  = await API.graphql({
            query: this.query,
            variables: this.input,
            //authMode: GRAPHQL_AUTH_MODE.AWS_IAM
        });
        console.log("====================");
        console.log({result});
        console.log("Size :: " + result.data[this.methodName.toString()].items.length);
        console.log("====================");
        

        if(this.finalArrayData === null){
            this.finalArrayData = result;
        }else{
            result.data[this.methodName.toString()].items.forEach(element => {
                this.finalArrayData.data[this.methodName.toString()].items.push(element);
            });
            this.finalArrayData.data[this.methodName.toString()].nextToken = result.data[this.methodName.toString()].nextToken;
        }
        console.log('final Size :: ' + this.finalArrayData.data[this.methodName.toString()].items.length);
        console.log('this.originalLimit :: ' + this.originalLimit);
        console.log('this.finalArrayData.data[this.methodName.toString()].items.length < this.originalLimit :: ' + this.finalArrayData.data[this.methodName.toString()].items.length < this.originalLimit);
        if(result.data[this.methodName.toString()].nextToken !== null){
            //this.input.limit = this.originalLimit - this.finalArrayData.data[this.methodName.toString()].items.length;
            this.input.nextToken = result.data[this.methodName.toString()].nextToken;
            await this.searchPrivateData();
        }
        return  this.finalArrayData;
    }
}

export default PagerQuery;