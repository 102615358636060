import { Layout, Loading, Message, Notification} from 'element-react';
import {useEffect, useState} from 'react';
import { API } from 'aws-amplify';
import {getCompany, listProducts, listUsers} from '../graphql/queries';
import {useParams} from "react-router-dom";
import MyPage from '../components/MyPage';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import userStore from '../store/user';
import {generateCompanyChat} from '../services/ChatService';
import {CHAT_COMPANY_MESSAGE} from '../utils/ChatConstants';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import sendEmailTemplate from '../graphql/lambdaInvokes';
import PagerQuery from '../utils/PagerQuery';

function YourStore(){
    let { companyid } = useParams();
    const [loading, setLoading] = useState(false);
    const [companyPage, setCompanyPage] = useState(null);
    const [owner, setOwner] = useState(null);
    const [featuresProduct, setFeaturesProduct] = useState([]);
    const [questionInput, setQuestionInput] = useState('');
    const [checkStatus, setCheckStatus] = useState(true);
    const {user} = userStore();
    let history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        console.log('MyStore');
        searchCompanyData(companyid);
    },[])
    const searchCompanyData = async(id) =>{
        console.log('searchCompanyData... from BD :: ' + id)
        setLoading(true);
        try{
            const input = {
                id
            }
            //const result = await API.graphql(graphqlOperation(getCompany, input));
            const result  = await API.graphql({
                query: getCompany,
                variables: input,
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM
              });
            console.log({result})
            //@ts-ignore
            setCompanyPage(result.data.getCompany);
            console.log('getting products');
            const resultProducts = await API.graphql({ 
                query: listProducts, 
                variables: { filter: {and: {owner: {eq: result.data.getCompany.owner}, featured: {eq: 1},  status: {eq: 1}}}},
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM
            });
            console.log({resultProducts});
            if(resultProducts.data.listProducts.items.length < 3){
                let limit = 3 - resultProducts.data.listProducts.items.length;
                console.log("limit: ", limit);
                const resultProducts2 = await API.graphql({
                    query: listProducts, variables: {
                        //limit: limit,
                        filter: {and: {owner: {eq: result.data.getCompany.owner}, 
                        //featured: {eq: 0},  
                        status: {eq: 1}}}
                    },
                    authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                });
                console.log({resultProducts2});
                if(resultProducts2.data.listProducts.items.length > limit){
                    //@ts-ignore
                    setFeaturesProduct(resultProducts.data.listProducts.items.concat(resultProducts2.data.listProducts.items.slice(0, limit)));
                }else{
                    //@ts-ignore
                    setFeaturesProduct(resultProducts.data.listProducts.items.concat(resultProducts2.data.listProducts.items));
                }
                
            }else{
                //@ts-ignore
                setFeaturesProduct(resultProducts.data.listProducts.items);
            }
            

            const filter = {
                cognito_id : {eq : result.data.getCompany.owner}
            }
            //const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter}});
            const inputListUsers = { filter: filter }
            const pagerQuery = new PagerQuery(listUsers, inputListUsers, Object.keys({ listUsers }));
            const resultCompanyUser = await pagerQuery.searchPrivateData();
            console.log({resultCompanyUser});
            setOwner(resultCompanyUser.data.listUsers.items[0]);
        }catch(e){
            console.error(e);
        }finally{
            setLoading(false);
        }
        
    }    

    const handleChatWithUs = async() =>{
        console.log('handletChatWithUs');
        if(user.type === "XYZ"){
            console.log('user seller cannot init a chat');
            Message({
                message: t('product.create.preview.error-seller'),
                type: 'error'
            });
            return;
        }
        setLoading(true);
        try{
            console.log({user})
            const filter = {
                cognito_id : {eq : companyPage.owner}
            }
            //const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter}});

            const pager = new PagerQuery(listUsers, {filter}, Object.keys({ listUsers }));
            const resultCompanyUser = await pager.searchPrivateData();

            console.log({resultCompanyUser});
            const chatResult  = await generateCompanyChat(CHAT_COMPANY_MESSAGE, companyPage, t, user, resultCompanyUser.data.listUsers.items[0]);
            if(chatResult === true){
                history.push("/my-chats/1");
            }
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
            if(e === 'No current user'){
                console.log('Alert to user');
                Message({
                    message: t('product.create.preview.no-session'),
                    type: 'error'
                });
            }
        }
    }

    const handleChatInputMessage = async() =>{
        console.log('handletChatWithUs');
        if(user.type === "XYZ"){
            console.log('user seller cannot init a chat');
            Message({
                message: t('product.create.preview.error-seller'),
                type: 'error'
            });
            return;
        }
        setLoading(true);
        try{
            if(questionInput != ''){
                console.log({user})
                const filter = {
                    cognito_id : {eq : companyPage.owner}
                }
                const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter}});
                console.log({resultCompanyUser});
                const chatResult  = await generateCompanyChat(questionInput, companyPage, t, user, resultCompanyUser.data.listUsers.items[0], checkStatus);
                if(chatResult === true){
                    await sendEmailTemplate({
                        templateName: "welcomeBuyer", 
                        subject: "This is a dummy email",
                        emailFrom: "eskasu@gmail.com",
                        recipients: ["nestors.silva@hotmail.com", "eskasu@gmail.com"], 
                        templateVars: {
                            fullname: "Juan Carlos"
                        },
                        templateLanguage: "es"
                    });
                    setQuestionInput('');
                    Notification({
                        title: 'Success',
                        message: t('product.create.preview.call-me-message'),
                        type: 'success'
                    });
                }
            }
            setLoading(false);   
        }catch(e){
            setLoading(false);
            console.error(e);
            if(e === 'No current user'){
                console.log('Alert to user');
                Message({
                    message: t('product.create.preview.no-session'),
                    type: 'error'
                });
            }
        }
    }

    if(loading)
        return <Loading fullscreen={loading} />
    if(companyPage)
        return <MyPage 
                    handleChatInputMessage={handleChatInputMessage} 
                    handleChatWithUs={handleChatWithUs} 
                    featuresProduct={featuresProduct} 
                    owner={false} 
                    ownerData={owner}
                    companyPage={companyPage}
                    questionInput={questionInput}
                    setQuestionInput={setQuestionInput}
                    checkStatus={checkStatus}
                    setCheckStatus={setCheckStatus}
                />
    return(
        <div className="my-store-div">
            <Layout.Row gutter="20">
                        <Layout.Col offset="1" span="20">
                            <p>&nbsp;ESTA EMPRESA AUN NO CUENTA CON UNA PAGINA --- PREGUNTAR A ALEX QUE PONER ESOS CASOS</p>
                        </Layout.Col>
            </Layout.Row>
        </div>
    )
}

export default YourStore;