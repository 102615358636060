
const countryOptions = {
    options: [
    {
        value: 'CAN',
        label: 'Canada',
        phoneCode: '+1'
    },
    {
        value: 'MEX',
        label: 'México',
        phoneCode: '+52'
    },
    {
        value: 'USA',
        label: 'United States',
        phoneCode: '+1'
    }
    ],
    value:''
}

const languageOptions = {
    options: [
        {
            value: 'en-US',
            label: 'English'
        },
        {
            value: 'es-ES',
            label: 'Español'
        }
    ]
}


const categoryPreferencesOptions = {
    options: [
        {
            value: 'Apparel & Textiles',
            translationCode: 'apparel-and-textiles',
            subcategories: [
                {
                    value:'Apparel',
                    translationCode:'appareal'
                },
                {
                    value:'Fiber & Fabrics',
                    translationCode:'fiber-and-fabrics'
                },
                {
                    value:'Home Textiles',
                    translationCode:'home-textiles'
                },
                {
                    value:'Textile Supplies',
                    translationCode:'textile-supplies'
                },
                {
                    value:'Textile Supplies',
                    translationCode:'textile-supplies'
                },
                {
                    value:'Textile Supplies',
                    translationCode:'textile-supplies'
                },
                {
                    value:'Textile Supplies',
                    translationCode:'textile-supplies'
                },
                {
                    value:'Textile Supplies',
                    translationCode:'textile-supplies'
                },
                {
                    value:'Textile Supplies',
                    translationCode:'textile-supplies'
                },
                {
                    value:'Textile Supplies',
                    translationCode:'textile-supplies'
                },
                {
                    value:'Textile Supplies',
                    translationCode:'textile-supplies'
                },
                {
                    value:'Textile Supplies',
                    translationCode:'textile-supplies'
                }
            ]
        },
        {
            value: 'Agriculture & Food',
            translationCode: 'agriculture-and-food',
            subcategories: [
                {
                    value:'Farming',
                    translationCode:'farming'
                },
                {
                    value:'Food & Beverages',
                    translationCode:'food-and-beverages'
                },
                {
                    value:'Animal Husbandry',
                    translationCode:'animal-and-husbandry'
                },
                {
                    value:'Agricultural Supplies',
                    translationCode:'agricultural-supplies'
                }
            ]
        },
        {
            value: 'Industrial Equipment',
            translationCode: 'industrial-equipment',
            subcategories: [
                {
                    value:'Analysis & Measurement',
                    translationCode:'analysis-and-measurement'
                },
                {
                    value:'Hydraulic & Pneumatic',
                    translationCode:'hydraulic-and-pneumatic'
                },
                {
                    value:'Lab Equipment',
                    translationCode:'lab-equipment'
                },
                {
                    value:'Tools & Equipment',
                    translationCode:'tools-and-equipment'
                }
            ]
        },
        {
            value: 'Sports & Recreation',
            translationCode: 'sports-and-recreation',
            subcategories: [
                {
                    value:'Fitness Equipment',
                    translationCode:'fitness-equipment'
                },
                {
                    value:'Outdoor Gear',
                    translationCode:'outdoor-gear'
                },
                {
                    value:'Recreational Goods',
                    translationCode:'reacreational-goods'
                },
                {
                    value:'Musical Instruments',
                    translationCode:'musical-instruments'
                }
            ]
        },
        {
            value: 'Electrical & Electronics',
            translationCode: 'electrical-and-electronics',
            subcategories: [
                {
                    value:'Audio & Video',
                    translationCode:'audio-and-video'
                },
                {
                    value:'Computer Components',
                    translationCode:'computer-components'
                },
                {
                    value:'Digital Devices',
                    translationCode:'digital-devices'
                },
                {
                    value:'Professional Lighting',
                    translationCode:'professional-lighting'
                }
            ]
        },
        {
            value: 'Advertising, Packaging & Printing',
            translationCode: 'advertising-packaging-and-printing',
            subcategories: [
                {
                    value:'Package Machinery',
                    translationCode:'package-machinery'
                },
                {
                    value:'Printing',
                    translationCode:'printing'
                },
                {
                    value:'Promotional Gifts',
                    translationCode:'promotional-gifts'
                },
                {
                    value:'Packaging Materials',
                    translationCode:'packing-materials'
                }
            ]
        },
        {
            value: 'Houseware & Office Supplies',
            translationCode: 'houseware-and-office-supplies',
            subcategories: [
                {
                    value:'Cleaning and Storage',
                    translationCode:'cleaning-and-storage'
                },
                {
                    value:'Gardening',
                    translationCode:'gardening'
                },
                {
                    value:'Pet Supplies',
                    translationCode:'pet-supplies'
                },
                {
                    value:'Stationery Supplies',
                    translationCode:'stationery-supplies'
                }
            ]
        },
        {
            value: 'Construction & Decoration',
            translationCode: 'construction-and-decoration',
            subcategories: [
                {
                    value:'Commercial Furniture',
                    translationCode:'commercial-forniture'
                },
                {
                    value:'Bathroom & Kitchen',
                    translationCode:'bathroom-and-kitchen'
                },
                {
                    value:'Home Furniture',
                    translationCode:'home-furniture'
                },
                {
                    value:'Stone & Sand',
                    translationCode:'stone-and-sand'
                }
            ]
        },
        {
            value: 'Chemicals, Materials & Energy', 
            translationCode: 'chemicals-materials-and-energy',
            subcategories: [
                {
                    value:'Agrochemicals',
                    translationCode:'agrochemicals'
                },
                {
                    value:'Energy',
                    translationCode:'energy'
                },
                {
                    value:'Petrochemicals',
                    translationCode:'petrochemicals'
                },
                {
                    value:'Pharmaceutical Chemicals',
                    translationCode:'pharmaceutical-chemicals'
                }
            ]
        },
        {
            value: 'Health & Personal Care', 
            translationCode: 'health-and-personal-care',
            subcategories: [
                {
                    value:'Animal Health',
                    translationCode:'animal-health'
                },
                {
                    value:'Dental Care Supplies',
                    translationCode:'dental-care-supplies'
                },
                {
                    value:'Medical Supplies',
                    translationCode:'medical-supplies'
                },
                {
                    value:'Personal Care',
                    translationCode:'personal-care'
                }
            ]
        },
        {
            value: 'Security & Protection', 
            translationCode: 'security-and-protection',
            subcategories: [
                {
                    value:'Access Control',
                    translationCode:'access-control'
                },
                {
                    value:'Alarms System',
                    translationCode:'alarms-system'
                },
                {
                    value:'Fire Control',
                    translationCode:'fire-control'
                },
                {
                    value:'Security Equipment',
                    translationCode:'security-equipment'
                }
            ]
        },
        {
            value: 'Transports & Components', 
            translationCode: 'transports-and-components',
            subcategories: [
                {
                    value:'ATV & Scooter',
                    translationCode:'atv-and-scooter'
                },
                {
                    value:'Marine & Aviation',
                    translationCode:'marine-and-aviation'
                },
                {
                    value:'Tractor & Trailer',
                    translationCode:'tractor-and-trailer'
                },
                {
                    value:'Auto Accessories',
                    translationCode:'auto-accesories'
                }
            ]
        },
    ],
    value:''
}

const businessDescriptionOptions = {
    options: [
        {
            value: 'Factory',
            translationCode: 'factory'
        },
        {
            value: 'Wholesaler',
            translationCode: 'wholesaler'
        },
        {
            value: 'Retailer',
            translationCode: 'retailer'
        },
        {
            value: 'Trading Company',
            translationCode: 'trading-company'
        },
        {
            value: 'Sourcing Agency',
            translationCode: 'sourcing-agency'
        },
        {
            value: 'Personal',
            translationCode: 'personal'
        }
    ],
    value:''
}

const canadaStates = {
    options : [
        {value:'Alberta', label:'Alberta'},
        {value:'Columbia Británica', label:'Columbia Británica'},
        {value:'Isla del Príncipe Eduardo', label:'Isla del Príncipe Eduardo'},
        {value:'Manitoba', label:'Manitoba'},
        {value:'Nueva Escocia', label:'Nueva Escocia'},
        {value:'Nuevo Brunswick', label:'Nuevo Brunswick'},
        {value:'Nunavut', label:'Nunavut'},
        {value:'Ontario', label:'Ontario'},
        {value:'Quebec', label:'Quebec'},
        {value:'Saskatchewan', label:'Saskatchewan'},
        {value:'Terranova y Labrador', label:'Terranova y Labrador'},
        {value:'Territorios del Noroeste', label:'Territorios del Noroeste'},
        {value:'Yukón', label:'Yukón'}
    ]
}

const mexStates = {
    options :[
        {
            value:'Aguascalientes',
            label:'Aguascalientes'
        },
        {
            value:'Baja California',
            label:'Baja California'
        },
        {
            value:'Baja California Sur',
            label:'Baja California Sur'
        },
        {
            value:'Campeche',
            label:'Campeche'
        },
        {
            value:'Chiapas',
            label:'Chiapas'
        },
        {
            value:'Chihuahua',
            label:'Chihuahua'
        },
        {
            value:'Ciudad de México',
            label:'Ciudad de México'
        },
        {
            value:'Coahuila',
            label:'Coahuila'
        },
        {
            value:'Colima',
            label:'Colima'
        },
        {
            value:'Durango',
            label:'Durango'
        },
        {
            value:'Guanajuato',
            label:'Guanajuato'
        },
        {
            value:'Guerrero',
            label:'Guerrero'
        },
        {
            value:'Hidalgo',
            label:'Hidalgo'
        },
        {
            value:'Jalisco',
            label:'Jalisco'
        },
        {
            value:'México',
            label:'México'
        },
        {
            value:'Michoacán',
            label:'Michoacán'
        },
        {
            value:'Morelos',
            label:'Morelos'
        },
        {
            value:'Nayarit',
            label:'Nayarit'
        },
        {
            value:'Nuevo León',
            label:'Nuevo León'
        },
        {
            value:'Oaxaca',
            label:'Oaxaca'
        },
        {
            value:'Puebla',
            label:'Puebla'
        },
        {
            value:'Querétaro',
            label:'Querétaro'
        },
        {
            value:'Quintana Roo',
            label:'Quintana Roo'
        },
        {
            value:'San Luis Potosí',
            label:'San Luis Potosí'
        },
        {
            value:'Sinaloa',
            label:'Sinaloa'
        },
        {
            value:'Sonora',
            label:'Sonora'
        },
        {
            value:'Tabasco',
            label:'Tabasco'
        },
        {
            value:'Tamaulipas',
            label:'Tamaulipas'
        },
        {
            value:'Tlaxcala',
            label:'Tlaxcala'
        },
        {
            value:'Veracruz',
            label:'Veracruz'
        },
        {
            value:'Yucatán',
            label:'Yucatán'
        },
        {
            value:'Zacatecas',
            label:'Zacatecas'
        }
    ]
}

const usaStates = {
    options : [
        {value:'Alabama', label:'Alabama'},
        {value:'Alaska', label:'Alaska'},
        {value:'Arizona', label:'Arizona'},
        {value:'Arkansas', label:'Arkansas'},
        {value:'California', label:'California'},
        {value:'Carolina del Norte', label:'Carolina del Norte'},
        {value:'Carolina del Sur', label:'Carolina del Sur'},
        {value:'Colorado', label:'Colorado'},
        {value:'Connecticut', label:'Connecticut'},
        {value:'Dakota del Norte', label:'Dakota del Norte'},
        {value:'Dakota del Sur', label:'Dakota del Sur'},
        {value:'Delaware', label:'Delaware'},
        {value:'Florida', label:'Florida'},
        {value:'Georgia', label:'Georgia'},
        {value:'Hawái', label:'Hawái'},
        {value:'Idaho', label:'Idaho'},
        {value:'Illinois', label:'Illinois'},
        {value:'Indiana', label:'Indiana'},
        {value:'Iowa', label:'Iowa'},
        {value:'Kansas', label:'Kansas'},
        {value:'Kentucky', label:'Kentucky'},
        {value:'Luisiana', label:'Luisiana'},
        {value:'Maine', label:'Maine'},
        {value:'Maryland', label:'Maryland'},
        {value:'Massachusetts', label:'Massachusetts'},
        {value:'Míchigan', label:'Míchigan'},
        {value:'Minnesota', label:'Minnesota'},
        {value:'Misisipi', label:'Misisipi'},
        {value:'Misuri', label:'Misuri'},
        {value:'Montana', label:'Montana'},
        {value:'Nebraska', label:'Nebraska'},
        {value:'Nevada', label:'Nevada'},
        {value:'Nueva Jersey', label:'Nueva Jersey'},
        {value:'Nueva York', label:'Nueva York'},
        {value:'Nuevo Hampshire', label:'Nuevo Hampshire'},
        {value:'Nuevo México', label:'Nuevo México'},
        {value:'Ohio', label:'Ohio'},
        {value:'Oklahoma', label:'Oklahoma'},
        {value:'Oregón', label:'Oregón'},
        {value:'Pensilvania', label:'Pensilvania'},
        {value:'Rhode Island', label:'Rhode Island'},
        {value:'Tennessee', label:'Tennessee'},
        {value:'Texas', label:'Texas'},
        {value:'Utah', label:'Utah'},
        {value:'Vermont', label:'Vermont'},
        {value:'Virginia', label:'Virginia'},
        {value:'Virginia Occidental', label:'Virginia Occidental'},
        {value:'Washington', label:'Washington'},
        {value:'Wisconsin', label:'Wisconsin'},
        {value:'Wyoming', label:'Wyoming'}
    ]
}

const unitOptions = {
    options: [{
        value: 'Piece',
        label: 'Piece'
      }, {
        value: 'Kilogram',
        label: 'Kilogram'
      }, {
        value: 'Gram',
        label: 'Gram'
      }, {
        value: 'Meter',
        label: 'Meter'
      }, {
        value: 'Sq. Meter',
        label: 'Sq. Meter'
      }, {
        value: 'Cu. Meter',
        label: 'Cu. Meter'
      }, {
        value: 'Litre',
        label: 'Litre'
      }, {
        value: 'Mililiter',
        label: 'Mililiter'
      }, {
        value: 'Set',
        label: 'Set'
      }, {
        value: 'Kit',
        label: 'Kit'
      }, {
        value: 'Roll',
        label: 'Roll'
      }, {
        value: 'Other',
        label: 'Other'
      }],
      value: ''
}

const warrantyOptions = {
        options : [{
            value: 'No',
            label: 'No'
          }, {
            value: '1 Year',
            label: '1 Year'
          }, {
            value: '2 Years',
            label: '2 Years'
          }, {
            value: '3 Years',
            label: '3 Years'
          }, {
            value: '4 Years',
            label: '4 Years'
          }, {
            value: '5 Years',
            label: '5 Years'
          }, {
            value: 'Lifetime',
            label: 'Lifetime'
          }],
          value: ''
}

const customerServiceOptions = {
    options : [{
        value: 'NA',
        label: 'NA'
      }, {
        value: 'English',
        label: 'English'
      }, {
        value: 'Spanish',
        label: 'Spanish'
      }, {
        value: 'English & Spanish',
        label: 'English & Spanish'
      }],
      value: ''
}

const statusOptions = {
    options : [{
        value: 0,
        label: 'Paused'
      }, {
        value: 1,
        label: 'Active'
      }, {
        value: 2,
        label: 'Archived'
      }],
      value: ''
}

const currencyOptions = {
    options: [
    {
        value: 'USD',
        label: 'USD'
    },
    {
        value: 'MXN',
        label: 'MXN'        
    }
    ],
    value:''
}

const guaranteeOptions = {
    options: [
    {
        value: 'YES',
        label: 'YES'
    },
    {
        value: 'NO',
        label: 'NO'        
    }
    ],
    value:''
}

const shippingStatusOptions = {
    options: [
    {
        value: 'Pending',
        label: 'Pending'
    },
    {
        value: 'Shipped',
        label: 'Shipped'        
    },
    {
        value: 'Delivered',
        label: 'Delivered'        
    }
    ],
    value:''
}


export {
    countryOptions, 
    languageOptions, 
    categoryPreferencesOptions, 
    businessDescriptionOptions,
    mexStates,
    canadaStates,
    usaStates,
    unitOptions,
    warrantyOptions,
    customerServiceOptions,
    statusOptions,
    currencyOptions,
    guaranteeOptions,
    shippingStatusOptions
};