import {useHistory} from "react-router-dom";
import { Layout, Button, Form, Input, Notification } from 'element-react';
import { useTranslation } from 'react-i18next';
import {useState, useRef, useEffect} from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import userStore from '../store/user';
import {listUsers, listBusinessPreferences, listCounterInfoTables } from '../graphql/queries';
import { updateUser, createCounterInfoTable } from '../graphql/mutations';
import {Link} from "react-router-dom";
import { useLastLocation } from 'react-router-last-location';
import prevNavStore from '../store/previousNav';
import loginImage from "../assets/imgs/login-image.png";
import logInDB from '../graphql/logLambdaInvokes';
import * as LogType from '../utils/LogType';
import '../../node_modules/reactjs-popup/dist/index.css';
import PagerQuery from '../utils/PagerQuery';
// import "../scss/_NonMenuForm.scss";

function Login(props){
    const {setUserInfo} = userStore();
    const { t } = useTranslation();
    const form = useRef(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const {path, setPath, clearPath} = prevNavStore();
    const userFieldRef = useRef();
    let history = useHistory();
    const lastLocation = useLastLocation();

    const [formInputs, setFormInputs] = useState(
        {
            username: '',
            password: ''
        }
    )

    const handleLogin = () =>{
        console.log('login');
        form.current.validate(async (valid) => {
            if (valid) {
                signIn();
            }else{

            }
        });
    }

    const createUserCounterRow = async (user) =>{
        try{
            
            const result = await API.graphql({ query: listCounterInfoTables, variables: 
                { 
                    filter: 
                        { 
                            owner: { eq: user.username } 
                        } 
                } 
            });
            console.log("=== listCounterInfoTables ===");
            console.log({result});
            if(result && result.data && result.data?.listCounterInfoTables?.items.length == 0){
                //Create new row...
                
                const inputNewCounterTable = {
                    //id: result.data.listUsers.items[0].id,
                    //cognito_id: user.username
                    owner: user.username,
                    products_total: 0,
                    active_orders_total: 0,
                    completed_orders_total: 0,
                    chats_total: 0,
                    extra1_total: 0,
                    extra2_total: 0,
                    extra3_total: 0,
                    extra4_total: 0,
                    extra5_total: 0
                };
                const resultInsert = await API.graphql(graphqlOperation(createCounterInfoTable, { input : inputNewCounterTable }));
                console.log({resultInsert});
            }else{
                console.log(' ------- listCounterInfoTables ALREADY exist -------')
            }
        }catch(e){
            console.error(e);
        }
    }

    async function signIn() {
        try {
            setLoadingBtn(true);
            //window["sendEvent"]("login", formInputs.username);

            const user = await Auth.signIn((formInputs.username + "").trim().toLowerCase(), formInputs.password);
            console.log({user});

            //TODO update user cognito_id
            //updateUser
            
            //const result = await API.graphql({ query: listUsers, variables: { filter: { username: { eq: (formInputs.username + "").trim().toLowerCase() } } } });
            const input = { filter: { username: { eq: (formInputs.username + "").trim().toLowerCase() } }}
            const pagerQuery = new PagerQuery(listUsers, input, Object.keys({ listUsers }));
            const result = await pagerQuery.searchPrivateData();
            console.log({result});

            console.log("getting business preferences ...")
            const resultBusinessPreferences = await API.graphql({ query: listBusinessPreferences, variables: { filter: { owner: { eq: (user.username + "").trim().toLowerCase() } }}});
            console.log(resultBusinessPreferences)
            
            const inputUserUpdate = {
                id: result.data.listUsers.items[0].id,
                cognito_id: user.username
            };
            const resultUpdate = await API.graphql(graphqlOperation(updateUser, { input : inputUserUpdate }));
            console.log({resultUpdate});
            const userDB = { 
                pendingSetupBusinessPreferences: (resultBusinessPreferences.data.listBusinessPreferences.items.length == 0), 
                ...resultUpdate.data.updateUser 
            }
            console.log('userDB')
            console.log(userDB);


            
            setUserInfo(user, userDB);

            //create counter row
            await createUserCounterRow(user);
            await logInDB({ message: 'login :: ' + formInputs.username, type: LogType.LOGIN_TYPE, user: user.username });
            setLoadingBtn(false);
            //setTimeout(function(){
                console.log(lastLocation);
                console.log('path :: ' + path);
                
                /*if(userDB.user_type == 'S'){
                    history.push("/dashboard");
                }else{*/
                    if(path == null){
                        if(lastLocation == null){
                            //history.push("/");
                            //do nothing, App.js already redirects you to /
                        } else{
                            history.push(lastLocation.pathname);
                        }
                    }else{
                        let _path = path;
                        setPath(null);
                        setTimeout(()=>{
                            history.push(_path);
                        },300);
                        
                    }
                //}
                
                
            //}, 500);
        } catch (error) {
            
            console.log('error signing in', error);
            let errorMsg = '';

            if(error.code === 'UserNotFoundException' || error.code === 'NotAuthorizedException'){
                errorMsg = t('login-form.error-user');
            }else{
                if(error.code === 'UserNotConfirmedException'){
                    errorMsg = t('login-form.error-not-confirmed');
                    setTimeout(function(){ 
                        history.push("/register-confirm/" + (formInputs.username + "").trim().toLowerCase());
                    }, 1000);
                }else{
                    errorMsg = t('login-form.error-general');
                }
                
            }
            Notification.error({
                title: 'Error',
                message: errorMsg
            });
            setLoadingBtn(false);
        }
    }

    const [rules, setRules] = useState(null);
    
    useEffect(() => {
        console.log("lastLocation :: " + lastLocation);
        console.log(lastLocation);
        setRules(
            {
                username: [
                    { required: true, message: t('register-form.validate-email'), type: 'email' }
                  ],
                  password: [
                    { required: true, message: t('register-form.validate-generic') }
                  ]
            }
        );
        let _timeToFocus = setTimeout(function(){
            userFieldRef.current.focus();
        }, 1000);
        
        return () => {
            console.log('Login will unmount');
            clearTimeout(_timeToFocus);
        }
    }, [t])

    const submitForm = (e) =>{
        e.preventDefault();
        handleLogin();
    }

    return(
        <div>
            <div className="login-card">
                <div className="login-card el-card el-card__body">
                    <Layout.Row justify="center" align="middle" className="hidden-sm-and-down">
                        <Layout.Col xs="24" sm="24" md="12" lg="12" className="login-card-image-col" style={{backgroundImage: `url(${loginImage})`}}>
                            <Layout.Row className="login-card-content">
                                <Layout.Col xs="24" sm="24" md="24" lg="24">
                                    <label className="login-card-title">{t('login-form.info-title')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-sales-comission')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-purchase-comission')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-promotions')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-customer-service')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-comission-free')}</label><br></br>
                                    <label className="login-card-title-2">{t('login-form.info-call-to-action')}</label>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="24" md="24" lg="24" className="login-card-button-col">
                                    <a className="white-primary-button" href="/register">
                                        {t('login-form.sign-up')}
                                    </a>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="24" md="24" lg="24">
                                   
                                </Layout.Col>
                            </Layout.Row>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="24" md="12" lg="12">
                            <Layout.Row className="roooooow m-t-4">
                                <Layout.Col xs="18" sm="14" md="18" lg="14" offset="3">
                                    <label className="form-title">
                                        {t('login-form.title')}
                                    </label>
                                    
                                    <Form ref={form} model={formInputs} 
                                        className="en-US" rules={rules} label-position="top"
                                        //onSubmit={handleSubmit(submitForm)}
                                        onSubmit={submitForm}
                                        >
                                        <Form.Item label={t('login-form.username')} prop="username">
                                            <Input 
                                                ref={userFieldRef}
                                                value={formInputs.username} 
                                                onChange={(v) => {setFormInputs({...formInputs, username: v })}} 
                                                trim={true}
                                                />
                                        </Form.Item>

                                        <Form.Item label={t('login-form.password')} prop="password">
                                            <Input 
                                                type="password"
                                                value={formInputs.password} 
                                                trim={true}
                                                onChange={(v) => {setFormInputs({...formInputs, password: v })}} 
                                                />
                                        </Form.Item>
                                        
                                        <Layout.Col span="24" className="align-center">
                                            <Form.Item>
                                                <Button id="btn_CreateAccount" nativeType="submit" value="Submit" type="primary" loading={loadingBtn} className="primary-button" >
                                                    {t('login-form.button-ok')}
                                                </Button>
                                            </Form.Item>

                                            <Form.Item>
                                                <Link to="/forgot-passwd">{t('login-form.forgot-passwd-label')}</Link>
                                            </Form.Item>
                                        </Layout.Col>
                                    </Form>
                                </Layout.Col>
                            </Layout.Row>
                        </Layout.Col>
                    </Layout.Row>

                    <Layout.Row justify="center" align="middle" className="hidden-md-and-up">    
                        <Layout.Col xs="24" sm="24" md="12" lg="12" offset="2">
                            <Layout.Row className="roooooow m-t-4">
                                <Layout.Col xs="18" sm="14" md="18" lg="14" offset="1">
                                    <label className="form-title">
                                        {t('login-form.title')}
                                    </label>
                                    
                                    <Form ref={form} model={formInputs} 
                                        className="en-US" rules={rules} label-position="top"
                                        //onSubmit={handleSubmit(submitForm)}
                                        onSubmit={submitForm}
                                        >
                                        <Form.Item label={t('login-form.username')} prop="username">
                                            <Input 
                                                ref={userFieldRef}
                                                value={formInputs.username} 
                                                onChange={(v) => {setFormInputs({...formInputs, username: v })}} 
                                                trim={true}
                                                />
                                        </Form.Item>

                                        <Form.Item label={t('login-form.password')} prop="password">
                                            <Input 
                                                type="password"
                                                value={formInputs.password} 
                                                trim={true}
                                                onChange={(v) => {setFormInputs({...formInputs, password: v })}} 
                                                />
                                        </Form.Item>
                                        
                                        <Layout.Col span="24" className="align-center">
                                            <Form.Item>
                                                <Button id="btn_CreateAccount" nativeType="submit" value="Submit" type="primary" loading={loadingBtn} className="primary-button" >
                                                    {t('login-form.button-ok')}
                                                </Button>
                                            </Form.Item>

                                            <Form.Item>
                                                <Link to="/forgot-passwd">{t('login-form.forgot-passwd-label')}</Link>
                                            </Form.Item>
                                        </Layout.Col>
                                    </Form>
                                </Layout.Col>
                            </Layout.Row>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="24" md="12" lg="12" className="login-card-image-col image-half" style={{backgroundImage: `url(${loginImage})`}}>
                            <Layout.Row className="login-card-content">
                                <Layout.Col xs="24" sm="24" md="24" lg="24">
                                    <label className="login-card-title">{t('login-form.info-title')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-sales-comission')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-purchase-comission')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-promotions')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-customer-service')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-comission-free')}</label><br></br>
                                    <label className="login-card-title-2">{t('login-form.info-call-to-action')}</label>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="24" md="24" lg="24" className="login-card-button-col">
                                    <a className="white-primary-button" href="/register">
                                        {t('login-form.sign-up')}
                                    </a>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="24" md="24" lg="24">
                                   
                                </Layout.Col>
                            </Layout.Row>
                        </Layout.Col>
                    </Layout.Row>
                </div>
            </div>
        </div>

    );
}

export default Login;