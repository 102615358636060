const registerRules = (t, refPassword, refUsername) => {
    return  ({
        country: [
            { required: true, message: t('register-form.validate-generic') }
        ],
        name: [
        { required: true, message: t('register-form.validate-generic'), trigger: 'blur' }
        ],
        lastName: [
            { required: true, message: t('register-form.validate-generic'), trigger: 'blur' }
        ]
        ,
        email: [
            { required: true, message: t('register-form.validate-email'), trigger: 'blur', type: 'email' }
        ]
        ,
        phoneNumber: [
            { required: true, message: t('register-form.validate-phonenumber'), trigger: 'blur', validator: (rule, value) => 
                {
                    var numbers = /^[0-9]+$/;
                    if (!value.match(numbers) || value.length < 10 || value.length > 12) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ]
        ,
        password: [
            { required: true, message: t('register-form.validate-passwd'), trigger: 'blur' , validator: (rule, value) =>
                {
                    /*const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])(?!.*\s).{8,}$/;
                    let usernameValue = "";
                    if (refUsername.current.props.value.includes("@")) {
                        usernameValue = refUsername.current.props.value.split("@")[0]
                        if (value.toLowerCase().includes(usernameValue.toLowerCase())) return false;
                        console.log("valid content")
                    }
                    if (value.length < 8) return false;
                    console.log("valid length")
                    if (!passwordRegex.test(value)) return false;
                    console.log("valid rules")
                    return true;*/
                    if(value.length < 8) return false;
                    return true;
                }
            }
        ]
        ,
        captcha: [
            { required: true, message: t('register-form.validate-generic'), trigger: 'blur' }
        ]
        ,
        termsAndConditions: [
            { type: 'array', required: true, message: t('register-form.validate-generic'), trigger: 'blur' }
        ]
        ,
        confirmPassword: [
            { required: true, message: t('register-form.validate-confirmpasswd'), trigger: 'blur', validator: (rule, value) => value === refPassword.current.props.value}
        ]
    })
}

export default registerRules;