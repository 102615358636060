import React from 'react';
import ContactUs from './ContactUs';
import HelpAndSupportComponent from '../components/HelpAndSupportComponent';

function HelpAndSupport() {
    return (
        <>
            <HelpAndSupportComponent/>
            <ContactUs hideTitle={true}/>
        </>
    );
}

export default HelpAndSupport;
