import React from 'react';
import { Collapse } from 'element-react';

function HelpAndSupportComponent() {
    const activeName = "0";
    return (
        <div>
            <h1 className="form-title">Bainub Help Center</h1>
            <br/>
            <h3 className="form-subtitle">Please browse the Bainub Help Topics.</h3>
            <h2 className='text-center'>FAQ</h2>
            <br/>
            <Collapse value={activeName} accordion>
                <Collapse.Item title="1. How do I find product suppliers in the USA, Mexico and Canada?" name="1">
                Easily find suppliers in bainub.com through our search engine. By using our online portal, you gain access to a comprehensive network 
                                            of reliable and certified suppliers, ensuring high-quality products for your business needs. Our B2B digital platform aims to connect
                                             businesses with specialized suppliers in the North American market for high volume trading operations.
                </Collapse.Item>
                <Collapse.Item title="2. Is a BAINUB a North American company?" name="2">
                Yes, we are proudly a North American enterprise with the main goal in mind to benefit the hard-working communities by creating a new supply 
                                            chain arrangement that turns our region into a world leading industrial power. This is why our motto is “Every Business in North America Linked Together”.
                </Collapse.Item>
                <Collapse.Item title="3. Is my information safe in BAINUB?" name="3">
                We take very seriously the commitment to guard the information that you share with us. We understand that you will share important and sensitive information, 
                                            so we invest into reliable infrastructure and prioritize our obligation to protect your data according to our Privacy Policy.
                                            We will only use your information to provide our service as a B2B ecommerce platform and to improve our processes for a better user experience.
                                            We work only with north American IT service suppliers and north American friendly partners.
                </Collapse.Item>
                <Collapse.Item title="4. What Is Nearshoring and  How does the nearshoring model benefit my business?" name="4">
                Nearshoring is a strategic business approach where a company delegates its business processes or services to a neighboring country or a nearby location. 
                                            BAINUB facilitates the commercial relationship between Mexico, United States and Canada by connecting you with clients or suppliers from anywhere in North America.
                </Collapse.Item>
                <Collapse.Item title="5. Is Baunub involved in my transactions?" name="5">
                No, Bainub provides the platform to connect suppliers with customers, then the traders can freely negotiate conditions and terms with each other for each of their transactions 
                                            without us requiring any participation into it.
                                            You and your trade partner have full ownership and responsibility over the transaction. Bainub won’t request any fee, commission, or participation as 
                                            part of your trading operations.
                </Collapse.Item>
                <Collapse.Item title="6. How to sell products online?" name="6">
                Bainub is an online platform to perform domestic or export business between companies in the Mexican, American or Canadian markets. Make your business profitable and expand your customer network anywhere in North America.
                </Collapse.Item>
            </Collapse>
            <br/>
            <h3 className='form-subtitle'>
                Can’t find what you are looking for?
            </h3>
            <h3 className='form-subtitle'>
                Just fill the form below and we will do our best to help.
            </h3>
            
        </div>
    );
}

export default HelpAndSupportComponent;
